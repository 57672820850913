import { collection, addDoc, doc, updateDoc } from "firebase/firestore";
import { createQuery, db } from "../providers/database";
import { JobDetailsNewCollection } from "../dataTypes/JobDetailsNewCollection";

import { collectionNames } from "./dictionary/collectionNames";
import { useQuery } from "react-query";

const jobDetailsNewCollectionRef = collection(
  db,
  collectionNames.jobDetailsNewCollection,
);

// Create a new entry for JobDetailsNewCollection
export async function createNewEntryForJobDetailsNewCollection(
  jobDetailsNew: JobDetailsNewCollection,
) {
  const newJob = await addDoc(jobDetailsNewCollectionRef, {
    ...jobDetailsNew,
  });
  return newJob.id;
}

// Update entry for JobDetailsNewCollection
export async function updateEntryForJobDetailsNewCollection(
  id: string,
  jobDetailsNew: JobDetailsNewCollection,
) {
  const updatedJob = await updateDoc(
    doc(db, collectionNames.jobDetailsNewCollection, id),
    {
      ...jobDetailsNew,
    },
  );
  return updatedJob;
}

export function useJobDetailsNewCollection(jobId: string) {
  return useQuery(
    ["JobDetailsNewCollection", { jobId }],
    createQuery(() => doc(db, collectionNames.jobDetailsNewCollection, jobId)),
    { enabled: !!jobId },
  );
}
