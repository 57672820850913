import { forwardRef, useState } from "react";
import { isDate } from "lodash";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../styles/DatePickerOverrides.css";
import CalendarSvg from "../icons/CalendarSvg";

interface DateProps {
  id?: string;
  name: string;
  display: string;
  initialStart?: Date | null;
  timestamp?: boolean;
  disabled?: boolean;
  value: Date | null;
  onChange?: (date: Date | null) => void;
  onBlur?: () => void;
  required?: boolean;
  error?: string;
  touched?: boolean;
  readOnly?: boolean;
}

interface CustomDateInputProps {
  display?: string;

  requiredAttr?: boolean;
  value?: string;
  onClick?: () => void;
  onBlurAttr?: () => void;
  readOnlyAttr?: boolean;
}
/* eslint-disable  */
{
  /*  @ts-ignore */
}
const CustomDateInput = forwardRef(
  (
    {
      value,
      onClick,
      display = "",
      readOnlyAttr,
      requiredAttr,
      onBlurAttr,
    }: CustomDateInputProps,
    ref: any,
  ) => {
    return (
      <div className="custom-date-input w-full">
        <label className="pl-1 text-instant-teams-teal-Main text-sm absolute duration-300 transform -translate-y-4 scale-75 top-4 origin-[0] left-2.5 peer-focus:text-instant-teams-blue-Main peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">
          {display}
          {requiredAttr && " *"}
          {readOnlyAttr ? " - LOCKED" : null}
        </label>

        <input
          ref={ref}
          className="form-input shadow-md rounded-md w-full pt-4 pb-3 text-[1rem] text-gray-800 placeholder-gray-400"
          type="text"
          value={value || ""}
          onClick={onClick}
          onBlur={onBlurAttr}
          onChange={(e) => e.preventDefault()}
          placeholder="Select date"
          readOnly={readOnlyAttr}
        />

        <span className="absolute right-5  w-2 top-4 h-full" onClick={onClick}>
          <CalendarSvg className="w-[20px] h-[20px]" />
        </span>
      </div>
    );
  },
);

CustomDateInput.displayName = "CustomDateInput";

const DateGeneric = forwardRef(
  (
    {
      id,
      name,
      display,
      initialStart = null,
      timestamp = false,
      disabled = false,
      onChange,
      onBlur,
      required,
      value,
      error,
      touched,
      readOnly = false,
      ...props
    }: DateProps,
    ref: any,
  ) => {
    {/*  @ts-ignore */}
    const [startDate, setStartDate] = useState<Date | null>(
      initialStart && isDate(initialStart) ? initialStart : null,
    );

    return (
      // <div id={name} className="grid gap-2 grid-cols-1 lg:grid-cols-2">
      <div id={name} className="w-full">
        <div className="relative mb-4">
          <DatePicker
            ref={ref}
            // showIcon
            minDate={new Date()}
            // selected={startDate}
            // onChange={(date: Date | null) => setStartDate(date)}
            selected={value}
            onChange={(date: Date | null) => onChange?.(date)}
            selectsStart
            startDate={startDate}
            // isClearable={!disabled}
            customInput={
              <CustomDateInput
                display={display}
                requiredAttr={required}
                readOnlyAttr={readOnly}
                onBlurAttr={onBlur ? onBlur : undefined}
              />
            }
            wrapperClassName="w-full"
            disabled={disabled}
            readOnly={readOnly}
            {...props}
          />
          {error && (
            <p className={"text-[0.8rem] font-medium text-[red] mt-1"}>
              {error}
            </p>
          )}
        </div>
      </div>
    );
  },
);

DateGeneric.displayName = "DateGeneric";

export default DateGeneric;
