import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { formatRateRange, formatString, formatTimeDifference, getDeviceType } from '../util';
import { useAuth } from '../../../../../../providers/auth';
import { updateUserBookmarks, getUserBookmarks } from '../../../../../../data/user';
import { applyNowMetric } from '../../../../../../callable-cloud-functions/cloudFunctions';
import { sanitizeMarkup } from '../../../../../../util/sanitization/sanitize-html';

const JobsDetails = ({ jobsDetailData, bookmarks, setBookmarks }) => {
    const data = useAuth();
    const commissionRef = useRef(null);
    useEffect(() => {
        const fetchBookmarks = async () => {
            const userBookmarks = await getUserBookmarks(data?.user?.id);
            setBookmarks(userBookmarks);
        };
        fetchBookmarks();
    }, [data]);

    const {
        companyLogo,
        companyFullName,
        jobName,
        openings,
        workType,
        workTerm,
        workSettings,
        makeLiveDate,
        payRate,
        applicationFlow,
        jobDescription,
        solutionType
    } = jobsDetailData || {};

    const timeDifference = formatTimeDifference(makeLiveDate);
    const platform = getDeviceType()


    const handleApplyJobs = async () => {
        try {
            // Wait for applyNowMetric to finish before opening the new window
            await applyNowMetric({ jobId: jobsDetailData?.id, userId: data?.user?.id, platform: platform });

            // After the metric is recorded, open the application flow in a new tab
            window.open(applicationFlow, '_blank');
        } catch (error) {
            console.error("Error applying for the job:", error);
        }
    };
    const handleBookMark = async () => {
        // Update Firestore with new bookmark status
        await updateUserBookmarks(data?.user?.id, jobsDetailData?.id);

        // Update local bookmarks state
        setBookmarks((prevBookmarks) => {
            if (Array.isArray(prevBookmarks) && prevBookmarks.some((bookmark) => bookmark.jobId === jobsDetailData?.id)) {
                return prevBookmarks.filter((bookmark) => bookmark.jobId !== jobsDetailData?.id);
            } else {
                return [...prevBookmarks, { jobId: jobsDetailData?.id, createdAt: new Date() }];
            }
        });
    };

    const scrollToCommission = () => {
        commissionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
    return (
        <div
            style={{
                scrollbarWidth: 'thin',
                scrollbarColor: '#b3b3b3 #f0f0f0',
            }}
            className="w-full mx-auto bg-white  rounded-lg overflow-y-auto overflow-x-hidden h-[92%] p-6 mt-12">
            {/* Header Section */}
            {jobsDetailData ? (
                <>
                    <div className="flex justify-between items-start">
                        <div className="flex">
                            <div className="mr-4">
                                <img
                                    src={solutionType == "bpo" ? "/images/InstantTeamsIcon-05.png" : companyLogo}
                                    alt="Company Logo"
                                    className="w-16 h-16 rounded object-contain "
                                />
                            </div>
                            <div>
                                <h2 className="text-xl font-semibold text-instant-teams-blue-Main">
                                    {jobName}
                                    {timeDifference?.includes("h ago") && <span className="text-red-500 ml-2">🔥</span>}
                                </h2>
                                <p className="text-gray-500 text-sm font-thin">{solutionType == "bpo" ? "Instantt Teams" : companyFullName}</p>
                            </div>
                        </div>
                        <div className="flex items-center space-x-4">
                            {openings > 1 && (
                                <span className="bg-instant-teams-teal-D2 text-gray-600 text-xs px-3 py-1 rounded-full">
                                    Multiple Openings
                                </span>
                            )}
                            {/* Bookmark Icon */}
                            <div className="flex-shrink-0 bg-gray-100 flex items-center justify-center w-8 h-8 rounded-full ml-4">
                                <button onClick={handleBookMark} className="text-gray-500 pl-2 pt-2 hover:text-gray-600">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 42 42"
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            fill={Array.isArray(bookmarks) && bookmarks.some((bookmark) => bookmark.jobId === jobsDetailData?.id) ? "#19405E" : ""}
                                            d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3-7 3V5z"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <button onClick={handleApplyJobs} className="bg-instant-teams-blue-Main text-white px-4 py-2 rounded-full flex items-center">
                                Apply Now <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2" />
                            </button>
                        </div>
                    </div>
                    <div className="mt-4 flex items-center space-x-5">
                        <div style={{ backgroundColor: "#1F385133" }} className="bg-gray-100 py-2 px-6 rounded-lg ">
                            <p className="text-instant-teams-blue-Main text-center  text-sm">Work Type</p>
                            <p className="font-semibold text-instant-teams-blue-Main text-center ">{formatString(workType)}</p>
                        </div>
                        <div style={{ backgroundColor: "#15415E33" }} className="bg-[#15415E] py-2 px-6 rounded-lg ">
                            <p className="text-instant-teams-blue-Main text-sm  text-center">Work Setting</p>
                            <p className="font-semibold text-instant-teams-blue-Main text-center">{formatString(workSettings)}</p>
                        </div>
                        {payRate?.salary && (
                            <div style={{ backgroundColor: "#3E4C9533" }} onClick={scrollToCommission} className={`${payRate?.commission ? "cursor-pointer" : ""} bg-[#3E4C9533] py-2 px-6  rounded-lg `}>
                                <p className="text-instant-teams-blue-Main text-center text-sm">{payRate?.commission ? "Salary + Commission" : "Salary"}</p>
                                <p className="font-semibold text-instant-teams-blue-Main text-center">{formatRateRange("salary", payRate?.salary)}</p>
                            </div>
                        )}
                        {payRate?.hourly && (
                            <div style={{ backgroundColor: "#3E4C9533" }} onClick={scrollToCommission} className="bg-[#3E4C9533] py-2 px-6  rounded-lg ">
                                <p className="text-instant-teams-blue-Main text-center text-sm">{payRate?.commission ? "Salary + Commission" : "Salary"}</p>
                                <p className="font-semibold text-instant-teams-blue-Main">{formatRateRange("hoursPerWeek", payRate?.hourly)}</p>
                            </div>
                        )}
                        {payRate?.commission && !payRate?.salary && !payRate?.hourly && (
                            <div style={{ backgroundColor: "#3E4C9533" }} className="bg-[#3E4C9533] py-2 px-6  rounded-lg ">
                                <p className="text-instant-teams-blue-Main font-thin  text-center text-sm">Salary</p>
                                <p className="font-semibold text-instant-teams-blue-Main">Commission Based</p>
                            </div>
                        )}
                        {payRate?.freeInternship && (
                            <div style={{ backgroundColor: "#3E4C9533" }} className="bg-[#3E4C9533] py-2 px-6  rounded-lg ">
                                <p className="text-instant-teams-blue-Main font-thin  text-center text-sm">Salary</p>
                                <p className="font-semibold text-instant-teams-blue-Main">Unpaid Internship</p>
                            </div>
                        )}
                    </div>

                    {/* Job Description */}
                    <div className="mt-6 border-t pt-6">
                        <div>
                            <h1 className='text-instant-teams-teal-Main'>
                                What's in it for you?
                            </h1>
                        </div>
                        <p className="text-gray-600 text-sm mt-2 leading-relaxed">
                            <div
                                className="dangerously"
                                dangerouslySetInnerHTML={sanitizeMarkup(
                                    jobDescription?.whatsInItForYou,
                                )}
                            ></div>

                        </p>
                        <div className='mt-5'>
                            <h1 className='text-instant-teams-teal-Main text-lg'>
                                A day in the Life
                            </h1>
                        </div>
                        <p className="mt-2 text-gray-600 text-sm">
                            <div
                                className="dangerously"
                                dangerouslySetInnerHTML={sanitizeMarkup(
                                    jobDescription?.dayInLife
                                )}
                            ></div>

                        </p>
                        <div className='mt-5'>
                            <h1 className='text-instant-teams-teal-Main'>
                                Must Haves/What You bring to the table
                            </h1>
                        </div>
                        <p className="mt-2 text-gray-600 text-sm">
                            <div
                                className="dangerously text-sm"
                                dangerouslySetInnerHTML={sanitizeMarkup(
                                    jobDescription?.mustHaves
                                )}
                            ></div>

                        </p>

                        {payRate.commission && <>
                            <div ref={commissionRef} className='mt-5'>
                                <h1 className='text-instant-teams-teal-Main'>
                                    Commission
                                </h1>
                            </div>
                            <p className="mt-2 text-gray-600 text-sm">
                                {payRate?.commission}
                            </p>
                        </>}
                    </div>
                </>
            ) : (
                <div className='flex items-center justify-center  h-full  '><h1 className='text-2xl text-gray-400 '> Select a Job</h1></div>
            )}
        </div>
    );
};

export default JobsDetails;
