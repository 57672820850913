import { useState, useImperativeHandle, forwardRef } from "react";
// @ts-ignore
import { ContentState, EditorState, convertToRaw } from "draft-js";
// @ts-ignore
import draftToHtml from "draftjs-to-html";
// @ts-ignore
import { Editor as DraftEditor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// @ts-ignore
import htmlToDraft from "html-to-draftjs"; // Use direct import
import { useEffect } from "react";
import { useCallback } from "react";

interface WysiwygProps {
  display: string;
  value?: string;
  onChange?: (value: string) => void; // Add onChange for react-hook-form
  onBlur?: () => void; // Add onBlur for react-hook-form
  rows?: number;
  readOnly?: boolean;
  placeholder?: string;
  editorStyle?: any;
  imageFolderLocation?: string;
  error?: string;
  touched?: boolean;
  required?: boolean;
}

const Wysiwyg = forwardRef(
  (
    {
      display,
      value: initialValue = "",
      onChange, // Get onChange from props for react-hook-form
      onBlur, // Get onBlur from props for react-hook-form
      rows = 5,
      readOnly = false,
      placeholder = "Start typing...",
      editorStyle = {},
      error,
      touched,
      required,
      imageFolderLocation = "images/wysiwyg/defaultFolder",
    }: WysiwygProps,
    ref: any,
  ) => {
    const [editorState, setEditorState] = useState(() =>
      initialValue ? prepareDraft(initialValue) : EditorState.createEmpty(),
    );

    const [hasManuallyEdited, setHasManuallyEdited] = useState(false);

    // Prepare the editor state from initial HTML value
    function prepareDraft(value: string) {
      const draft = htmlToDraft(value);
      const contentState = ContentState.createFromBlockArray(
        draft.contentBlocks,
      );
      return EditorState.createWithContent(contentState);
    }

    // Sync editorState with value prop (for reset)
    useEffect(() => {
      if (!hasManuallyEdited && initialValue) {
        setEditorState(prepareDraft(initialValue)); // Update editorState only if user hasn't manually edited
      }
    }, [initialValue, hasManuallyEdited]);

    // Handle editor state change and call onChange prop
    const onEditorStateChange = useCallback(
      (newEditorState: EditorState) => {
        setEditorState(newEditorState);
        const content = draftToHtml(
          convertToRaw(newEditorState.getCurrentContent()),
        );

        if (onChange) {
          let filteredEmptyContent = content === "<p></p>\n" ? "" : content;
          onChange(filteredEmptyContent); // Triggering the form's onChange event
        }
        setHasManuallyEdited(true); // Mark that user has edited content manually
      },
      [onChange],
    );

    // Expose the getHtmlContent method via ref
    useImperativeHandle(ref, () => ({
      getHtmlContent: () => {
        const content = draftToHtml(
          convertToRaw(editorState.getCurrentContent()),
        );
        return content;
      },
    }));

    // Callback function to handle image upload
    const uploadCallback = (file: File) => {
      const fileName = file.name;
      const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
      const newFileName = `${timestamp}_${fileName}`;
      return new Promise<{ data: { link: string } }>((resolve) => {
        setTimeout(
          () => resolve({ data: { link: `/path/to/${newFileName}` } }),
          1000,
        );
      });
    };

    return (
      <div className="relative mb-4">
        <div className="text-instant-teams-teal-Main font-bold pl-1 ">
          {display}
          {required && " *"}
          {readOnly ? " - LOCKED" : null}
        </div>
        {/*  @ts-ignore */}
        <DraftEditor
          readOnly={readOnly}
          spellCheck={true}
          editorStyle={editorStyle}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          wrapperClassName="shadow-md rounded-md bg-white pt-3"
          editorClassName={`pl-4 pr-4 h-${rows * 12}`}
          toolbarClassName="border-solid border-b-2 border-sky-500 p-2 text-xs"
          placeholder={placeholder}
          toolbar={{
            options: [
              "inline",
              "blockType",
              "link",
              // "image",
              "list",
              "textAlign",
            ],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            list: {
              options: ["unordered", "ordered"],
            },
            textAlign: {
              options: ["left", "center", "right", "justify"],
            },
            image: {
              uploadCallback: uploadCallback,
            },
          }}
          onBlur={onBlur} // Call form's onBlur event
        />

        {error && (
          <p className={"text-[0.8rem] font-medium text-[red] mt-1"}>{error}</p>
        )}
      </div>
    );
  },
);

export default Wysiwyg;
