import { filter } from "lodash";

export function formatTimeDifference(makeLiveDate) {
    const currentDate = new Date();
    const liveDate = new Date(makeLiveDate?.seconds * 1000);
    const diffInMilliseconds = currentDate - liveDate;
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInHours < 24) {
        return diffInHours > 0 ? `${diffInHours}h ago` : `${Math.floor(diffInMinutes)}m ago`;
    } else {
        return diffInDays <= 7 ? `${diffInDays}d ago` : null;
    }
}

export function formatString(input) {
    return input?.split("_")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}

export function formatRateRange(type, data) {
    if (type === 'salary') {
        return `$${data.min.toLocaleString()} - $${data.max.toLocaleString()} Yearly`;
    } else if (type === 'hoursPerWeek') {
        return `$${data.min} - $${data.max} Hourly`;
    } else {
        return 'Invalid type';
    }
}

export function filterJobs(jobs, keywords) {
    if (!keywords) return jobs;
    const lowerCaseKeywords = typeof keywords === 'string' ? keywords.toLowerCase() : keywords;
    return jobs.filter(job => {
        let match = true;
        if (typeof lowerCaseKeywords === 'string') {
            const keyword = lowerCaseKeywords;
            match = (
                job.jobName?.toLowerCase().includes(keyword) ||
                job.companyFullName?.toLowerCase().includes(keyword)
            );
        } else {
            const {
                workSetting,
                workType,
                postedBy,
                salaryRange
            } = lowerCaseKeywords;
            match = (
                (job.workSettings?.toLowerCase() === workSetting?.toLowerCase()) ||
                (job.workType?.toLowerCase() === workType?.toLowerCase()) ||
                (job.payRate?.type?.toLowerCase() === salaryRange?.toLowerCase())
            );
        }
        return match;
    });
}
export function filterCompany(companyData, keywords) {
    if (!keywords) return companyData;
    return companyData.filter((data) => data.displayName.toLowerCase().includes(keywords.toLowerCase()))
}

export const filterJobsByCriteria = (jobs, filters, bookmarks) => {
    const filterData = jobs.filter(job => {
        const makeLiveDate = new Date(job?.makeLiveDate?.seconds * 1000);

        if (filters.workSetting === "temp_to_perm") {
            if (filters.workSetting && job.workTerm !== filters.workSetting) {
                return false;
            }
        } else {
            if (filters.workSetting && job.workSettings !== filters.workSetting) {
                return false;
            }
        }


        if (filters.workType && job.workType !== filters.workType) {
            return false;
        }

        if (filters.postedBy) {
            const currentDate = new Date();

            if (filters.postedBy === 'last24hrs') {
                const past24HoursDate = new Date(currentDate);
                past24HoursDate.setHours(currentDate.getHours() - 24);
                if (makeLiveDate < past24HoursDate) return false;
            } else if (filters.postedBy === 'lastWeek') {
                const pastWeekDate = new Date(currentDate);
                pastWeekDate.setDate(currentDate.getDate() - 7);
                if (makeLiveDate < pastWeekDate) return false;
            } else if (filters.postedBy === 'lastMonth') {
                const pastMonthDate = new Date(currentDate);
                pastMonthDate.setMonth(currentDate.getMonth() - 1);
                if (makeLiveDate < pastMonthDate) return false;
            }
        }


        if (filters.salaryRange) {
            const minSalary = filters.salaryRange.min;
            const maxSalary = filters.salaryRange.max;
            if (filters.salaryRangeType === 'hourly') {
                const hourlyMin = job.payRate?.hourly?.min || 0;
                const hourlyMax = job.payRate?.hourly?.max || 0;

                if (
                    !(hourlyMin >= minSalary && hourlyMax <= maxSalary) &&
                    !(hourlyMin <= maxSalary && hourlyMax >= minSalary)
                ) {
                    return false;
                }
            }
            if (filters.salaryRangeType === 'salary') {
                const salaryMin = job.payRate?.salary?.min || 0;
                const salaryMax = job.payRate?.salary?.max || 0;

                if (
                    !(salaryMin >= minSalary && salaryMax <= maxSalary) &&
                    !(salaryMin <= maxSalary && salaryMax >= minSalary)
                ) {
                    return false;
                }
            }
            if (filters.salaryRangeType === 'commission' && !job.payRate?.commission) {
                return false;
            }
            if (filters.salaryRangeType === 'free_internship' && !job.payRate?.freeInternship) {
                return false;
            }
        }
        if (filters.instantTeamsJobs && job.solutionType !== "bpo") {
            return false;
        }
        if (filters.multipleOpenings && job.openings <= 1) {
            return false;
        }

        const bookmarkedJobIds = bookmarks?.map(bookmark => bookmark?.jobId);

        if (filters.bookmarkedJobs && !bookmarkedJobIds.includes(job.id)) {
            return false;
        }

        return true;
    });

    return filterData;
};

export function sortByFilter(jobsData, sortedBy, setFilterJobs) {
    if (sortedBy === "instanteamsJobs") {

        const instantteamsJobs = jobsData.filter(
            (data) => data.solutionType === "bpo"
        );
        const otherJobs = jobsData.filter(
            (data) => data.solutionType !== "bpo"
        );
        const combinedJobs = [...instantteamsJobs, ...otherJobs];
        setFilterJobs(combinedJobs);
    } else if (sortedBy === "datePosted") {
        const sortedJobs = [...jobsData].sort((a, b) => {
            const dateA = new Date(a?.makeLiveDate?.seconds * 1000);
            const dateB = new Date(b?.makeLiveDate?.seconds * 1000);
            return dateB - dateA;
        });
        setFilterJobs(sortedJobs);
    } else if (sortedBy === "all") {
        setFilterJobs(jobsData);
    }
}

export function filterByLatestJobs(jobsData) {
    let latestJobs
    if (jobsData) {
        latestJobs = [...jobsData].sort((a, b) => {

            const dateA = new Date(a.makeLiveDate?.seconds * 1000);
            const dateB = new Date(b.makeLiveDate?.seconds * 1000);
            return dateB - dateA;
        });
    }
    

    return latestJobs
}


export const getDeviceType = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {          
      return 'IOS_Web';
    }
    if (/Android/.test(userAgent)) {
      return 'Android_Web';
    }
    return 'Web';
  };








