import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from "../../../providers/auth";

import { useFindLookup } from "../../../data/lookup";
import { addUserAffiliation, addUserAffiliationNew, addOrUpdateUserAffiliation, deleteAffiliations } from "../../../data/affiliation";
import { updateAutoSave } from "../../../data/utility";

import {
  collectionNames,
  subCollectionNames,
} from "../../../data/dictionary/collectionNames";
import { createResume } from "../../../data/seeker";
import { useLookup } from "../../../data/lookup";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import ToastAlert from "../../../components/custom-ui/toast-alert/ToastAlert";
import { useAffiliations } from "../../../data/affiliation";
import AffiliationItem from '../details/parts/AffiliationsItem';
import { useUser } from "../../../providers/database";
import { updateAddress } from '../../../data/utility';



function MilitarySettings() {
  //const { register, handleSubmit, formState: { errors } } = useForm();
  const auth = useAuth();
  const { user } = auth;
  const [isModalMilitary, setIsModalMilitary] = useState(false);


  const [affiliationDocId, setAffiliationDocId] = useState();
  const { data: branchList = [] } = useLookup("branch");
  const { data: affiliationList = [] } = useLookup("affiliation");
  const { data: stateList = [] } = useLookup("state");
  const { data: affiliation, error: affiliationError } = useAffiliations(user?.id);
  const { data: userData } = useUser(auth?.user?.uid);

  console.log(affiliation, "affiliation data")


  // State to track whether user is military connected
  const [pending, setPending] = useState(false);
  //const [branch, setbranch] = useState('No');
  //const initialAffiliation = affiliation?.[0] || {}; // Safe access to the first object in the array
  const [branch, setbranch] = useState();
  const [selectedBranch, setSelectedBranch] = useState('');
  const [selectedAffiliation, setSelectedAffiliation] = useState('');

  const [stateR, setStateR] = useState(userData?.address?.state)

  const { data: branchDataN } = useFindLookup(
    "branch",
    selectedBranch,
  );

  const { data: stateD } = useFindLookup(
    "state",
    stateR,
  );


  const { data: affiliationDataN, } = useFindLookup(
    "affiliation",
    selectedAffiliation
  );
  console.log("stateD", userData.affiliationData?.stateResidence, stateD);
  useEffect(() => {
    if (affiliation && affiliation.length > 0) {
      const initialAffiliation = affiliation[affiliation.length - 2];
      setSelectedBranch(affiliation[0]?.branch);
      setSelectedAffiliation(affiliation[0]?.affiliation);
      setbranch("Yes")
    } else {
      setbranch("No")
    }
  }, [affiliation]);


  // useEffect(() => {
  //   if (branch == "No") {
  //     deleteAffiliations(user.id)
  //   }

  // }, [branch])

  useEffect(() => {
    setStateR(userData.address?.state)

  }, [userData]);


  const affiliationSchema = z.object({
    stateResidence: z
      .string({ required_error: "State of residence is required" })
      .nonempty({ message: "State of residence is required" }),
  });

  type AffiliationSchema = z.infer<typeof affiliationSchema>;

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    getValues,
    control,
  } = useForm<AffiliationSchema>({
    resolver: zodResolver(affiliationSchema),
  });

  const closeModal = () => {
    setIsModalMilitary(false);

  };


  const onSubmit = async (data: AffiliationSchema) => {
    try {
      // Extract stateResidence from the form data
      const { stateResidence } = data;
      
      // Update state locally
      setStateR(stateResidence);
      
      // Update user affiliation and address in Firestore
      if (branch=="Yes") {
        await addOrUpdateUserAffiliation(user?.id, selectedBranch, selectedAffiliation);
        await updateAddress(collectionNames.users, user.id, stateResidence);
      } else {
        await updateAddress(collectionNames.users, user.id, stateResidence);
      }
 
      
      // // Create resume after successful updates
      // await createResume({ id: user.id, stateResidence });
      
      // If everything is successful, update the state and show success message
      setPending(false);
      toast.success(`Military Affiliation submitted`);
      setIsModalMilitary(false);
  
    } catch (error) {
      // Handle any error that occurred in the process
      console.error("Error updating data: ", error);
      toast.error("An error occurred while submitting the affiliation. Please try again.");
      
      // Make sure the pending state is turned off in case of failure
      setPending(false);
    }
  };

  const handleDeleteAffiliation = () => {
    setbranch("No")
    setSelectedBranch("");
    setSelectedAffiliation("")
    deleteAffiliations(user.id).then(() => { setbranch("No"); })
    
  }
  

  return (
    <>


      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-bold text-[#15415E]">Military Affiliation</h2>
        <button className="bg-[#15415E] text-white px-6 py-2 rounded-full flex items-center w-34 h-10" onClick={() => setIsModalMilitary(true)}>
          Edit
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            className="w-4 h-4 ml-1"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-7.036a2.5 2.5 0 113.536 3.536L7.5 19.5H4v-3.536l11.732-11.732z" />
          </svg>
        </button>
      </div>

      <div className="space-y-4">
        <div>
          <p className="text-base text-gray-600">Are you military affiliated?</p>
          <p className="text-base text-gray-900">{branch}</p>
        </div>
        {branch === 'Yes' && (
          <>
            <hr className="border-gray-300" />
            <div>
              <p className="text-base text-gray-600">What is your Military Branch?</p>
              <p className="text-base text-gray-900">{branchDataN && branchDataN?.length > 0 ? branchDataN[0]?.label : ""}</p>
            </div>
            <hr className="border-gray-300" />
            <div>
              <p className="text-base text-gray-600">What is your Military Affiliation?</p>
              <p className="text-base text-gray-900">{affiliationDataN && affiliationDataN?.length > 0
                ? affiliationDataN[0]?.label
                : ""}</p>
            </div>

          </>
        )}
        <hr className="border-gray-300" />
        <div>
          <p className="text-base text-gray-600">What state do you currently reside in?</p>
          <p className="text-base text-gray-900">{stateD && stateD.length > 0 ? stateD[0]?.label : ''}</p>
        </div>

      </div>
      {isModalMilitary && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black opacity-50" onClick={closeModal}></div>
          <div
            className={`relative transform transition-transform duration-300 ease-in-out bg-white rounded-2xl w-4/12 shadow-lg p-8`}

          >
            <button
              className="absolute top-4 right-4 bg-white p-1 rounded-full"
              onClick={() => closeModal()}
            >
              <img src="/images/closeIcon.png" alt="Close Icon" className="w-8 h-8" />
            </button>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">

              <div className="flex mb-5">

                <p className="border-b w-full py-2 text-xl text-[#15415E]">Update Military Affiliation</p>
              </div>

              <div className="mb-6 pb-4 border-b border-gray-300">
                <label className="block text-lg font-medium text-[#15415E] mb-2">
                  Are you military affiliated?
                </label>
                <div className="flex items-center space-x-8">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      value="Yes"

                      checked={branch === 'Yes'}
                      onChange={() => setbranch('Yes')}
                      className="form-radio h-5 w-5 text-[#15415E]"
                    />
                    <span className="ml-2 text-[#15415E] text-sm">Yes</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      value="No"

                      checked={branch === 'No'}
                      onChange={() => handleDeleteAffiliation()}
                      className="form-radio h-5 w-5 text-[#15415E]"
                    />
                    <span className="ml-2 text-[#15415E] text-sm">No</span>
                  </label>
                </div>

              </div>

              {branch === 'Yes' && (
                <>
                  {/* Military Branch */}
                  <div className="mb-6 pb-4 border-b border-gray-300">
                    <label className="block text-lg font-medium text-[#15415E] mb-2">
                      What is your Military Branch?
                    </label>
                    <div className="grid grid-cols-2 gap-4">
                      {branchList.map((branch: any) => (
                        <label key={branch.id} className="inline-flex items-center">
                          <input

                            type="radio"
                            value={branch.code}
                            checked={selectedBranch === branch.code}
                            onChange={() => setSelectedBranch(branch.code)}
                            name="branch"
                            className="form-radio h-5 w-5 text-[#15415E]"
                          />
                          <span className="ml-2 text-[#15415E] text-sm">{branch.label}</span>
                        </label>
                      ))}
                    </div>

                  </div>

                  {/* Military Affiliation */}
                  <div className="mb-6 pb-4 border-b border-gray-300">
                    <label className="block text-lg font-medium text-[#15415E] mb-2">
                      What is your Military Affiliation?
                    </label>
                    <div className="grid grid-cols-2 gap-4">
                      {affiliationList.map((affiliation: any) => (
                        <label key={affiliation.id} className="inline-flex items-center">
                          <input

                            type="radio"
                            value={affiliation.code}
                            name="affiliation"
                            checked={selectedAffiliation === affiliation.code}
                            onChange={() => setSelectedAffiliation(affiliation.code)}
                            className="form-radio h-5 w-5 text-[#15415E]"
                          />
                          <span className="ml-2 text-[#15415E] text-sm">{affiliation.label}</span>
                        </label>
                      ))}
                    </div>

                  </div>
                </>
              )}

              {/* State of Residence */}
              <div className="mb-6 border-b border-gray-300">
                <label className="block text-lg font-sm text-[#15415E] mb-2">
                  Which state do you currently reside in?  <span className="text-red-600">*</span>
                </label>

                <select
                  className={`py-2 px-3 font-bold w-full leading-8 font-medium borrder-none focus:ring-1 ${errors.stateResidence ? "border-red-600" : ""
                    }`}
                  style={{ color: '#15415E', boxShadow: 'none' }}
                  name="stateResidence"
                  ref={register({
                    required: "Please select your state of residence.",
                  })}
                  defaultValue={userData?.address?.state || ""}
                >
                  <option value="" selected disabled>
                    State
                  </option>
                  {stateList.map((state: any) => (
                    <option key={state.id} value={state.code}>
                      {state.label}
                    </option>
                  ))}
                </select>
                {errors.stateResidence && (
                  <p className="m-2 text-sm text-left text-red-600">
                    {errors.stateResidence.message}
                  </p>
                )}
              </div>

              {/* Submit Button */}




              <div className="flex justify-around space-x-10 mt-10 mb-4 mx-5">

                <button
                  className="py-2 bg-whit border border-instant-teams-blue-Main hover:border-instant-teams-blue-D1 hover:text-white hover:bg-instant-teams-blue-D1 text-black w-full h-12 rounded-full"
                  onClick={() => closeModal()}
                >
                  Cancel
                </button>
                <button
                  type="submit"
            
                  className="py-2 px-8 text-white font-medium rounded-lg focus:outline-none focus:ring-2 rounded-full w-full"
                  style={{ backgroundColor: '#15415E' }}
                  disabled={pending}
                >
                  {pending ? (
                    <div className="flex items-center justify-center space-x-2">
                      <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                      </svg>

                    </div>
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>

            </form>
          </div>
        </div>
      )}
      <ToastAlert />
    </>
  );
}

export default MilitarySettings;
