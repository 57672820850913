import React, { useEffect, useState } from 'react';
import { formatTimeDifference, formatString, formatRateRange } from '../util';
import { updateUserBookmarks, getUserBookmarks } from '../../../../../../data/user';
import { viewJobMetric } from '../../../../../../callable-cloud-functions/cloudFunctions';
import { useAuth } from '../../../../../../providers/auth';

import { getDeviceType } from '../util';

const JobsCards = (
    { jobsData,
        setJobsDetailData = () => { },
        setBookmarks = () => { },
        bookmarks = [],
        setSelectedJobId = () => { },
        selectedJobId = () => { },
        userData = {},
        selectedId = ""
    }
) => {

    const {
        companyLogo,
        companyFullName,
        jobName,
        openings,
        workType,
        workTerm,
        workSettings,
        makeLiveDate,
        payRate,
        solutionType
    } = jobsData || {}

    const timeDifference = formatTimeDifference(makeLiveDate);
    const auth = useAuth()

    const platform = getDeviceType()
    const handleJobsDetail = () => {
        setJobsDetailData(jobsData);
    }

    useEffect(() => {
        setSelectedJobId(selectedId)
    }, [selectedId])
    const handleClick = () => {
        viewJobMetric({jobId:jobsData.id, userId:auth?.user?.id, platform:platform})
        setJobsDetailData(jobsData);
        setSelectedJobId(jobsData.id); // Update the selected job ID
    };

    const isSelected = selectedJobId === jobsData.id;

    const handleBookMark = async () => {
        await updateUserBookmarks(userData.user.id, jobsData?.id);
        setBookmarks((prevBookmarks) => {
            if (Array.isArray(prevBookmarks) && prevBookmarks.some((bookmark) => bookmark.jobId === jobsData?.id)) {
                return prevBookmarks.filter((bookmark) => bookmark.jobId !== jobsData?.id);
            } else {
                return [...prevBookmarks, { jobId: jobsData?.id, createdAt: new Date() }];
            }
        });
    }

    useEffect(() => {
        const fetchBookmarks = async () => {
            const updatedBookmarks = await getUserBookmarks(userData.user.id);
            setBookmarks(updatedBookmarks);
        };

        fetchBookmarks();
    }, [userData.user.id, setBookmarks]);

    return (
        <div onClick={handleClick} className={`relative  mx-auto  mb-3  bg-white rounded-2xl p-4 flex flex-col md:flex-row items-start ${isSelected ? "border-2 border-instant-teams-blue-Main " : ""} `}>
            {openings > 1 && (
                <span className="absolute top-0 right-0 bg-instant-teams-teal-D2 text-xs text-gray-600 px-2 py-1 rounded-tr-2xl ">
                    Multiple Openings
                </span>
            )}
            <div className="flex w-full">
                <div className="flex-shrink-0">
                    <img
                        src={solutionType=="bpo"? "/images/InstantTeamsIcon-05.png":companyLogo}
                        alt="Company Logo"
                        className="w-16 h-16 rounded object-contain"
                    />
                </div>
                <div className="ml-2 flex-grow">
                    <div className="flex justify-between items-center">
                        <div className='cursor-pointer' onClick={handleJobsDetail}>
                            <p className="text-sm text-gray-500">{solutionType=="bpo"?"Instantt Teams" :companyFullName}</p>
                            <h2 className="text-lg font-semibold text-instant-teams-blue-Main">
                                {jobName}
                                {(timeDifference?.includes("m") || timeDifference?.includes("h"))&&<span className="ml-2 text-red-500">🔥</span>}
                            </h2>
                            {payRate?.salary && <p className="text-sm text-gray-600">{formatRateRange("salary", payRate?.salary)}</p>}
                            {payRate?.hourly && <p className="text-sm text-gray-600">{formatRateRange("hoursPerWeek", payRate?.hourly)}</p>}
                            {payRate?.commission && <p className="text-sm text-gray-600">Commission Based</p>}
                            {payRate?.freeInternship && <p className="text-sm text-gray-600">Unpaid Internship</p>}
                        </div>
                        <div className="flex-shrink-0 mt-5 bg-gray-100 flex items-center justify-center w-8 h-8 rounded-full ml-4">
                            <button onClick={handleBookMark} className="text-gray-500 pl-2 pt-2 hover:text-gray-600">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 42 42"
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        fill={Array.isArray(bookmarks) && bookmarks.some(bookmark => bookmark.jobId === jobsData.id) ? "#19405E" : ""}
                                        d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3-7 3V5z"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-grow items-end justify-center w-full">
                <div className="flex space-x-3 mt-3">
                    <span className="bg-gray-100 text-gray-800 text-sm px-8 py-1 rounded-full">{formatString(workSettings)}</span>
                    <span className="bg-gray-100 text-gray-800 text-sm px-8 py-1 rounded-full">{formatString(workType)}</span>
                    {timeDifference && <span className="bg-gray-100 text-gray-800 text-sm px-8 py-1 rounded-full">Posted: {timeDifference}</span>}
                </div>
            </div>
        </div>
    );
};

export default JobsCards;
