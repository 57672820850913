import { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useLookup } from "../../../data/lookup";
import {
  updateEntryForJobDetailsNewCollection,
  useJobDetailsNewCollection,
} from "../../../data/jobDetailsNewCollection";

import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import { isLockedJobNew } from "../../../util/jobs/jobUtils";

import Input from "../../custom-ui/old-ui/Input";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { JobsNewSchema } from "../../../schemas/JobsNewSchema";
import Wysiwyg from "../../custom-ui/old-ui/Wysiwyg";
import SelectGeneric from "../../custom-ui/old-ui/SelectGeneric";
import DateGeneric from "../../custom-ui/old-ui/DateGeneric";
import { JobDetailsNewCollection } from "../../../dataTypes/JobDetailsNewCollection";
import RadioGroup from "../../custom-ui/old-ui/RadioGroup";
import toast from "react-hot-toast";
import ToastAlert from "../../../components/custom-ui/toast-alert/ToastAlert";
import Checkbox from "../../custom-ui/old-ui/Checkbox";
import InputCurrency from "../../custom-ui/old-ui/InputCurrency";
import TextArea from "../../custom-ui/old-ui/TextArea";
import { useMemo } from "react";

function JobNewInformation() {
  const params = useParams();
  const jobId = params.id ?? "";

  const [isInitialized, setIsInitialized] = useState(false);

  const { data: job } = useJobDetailsNewCollection(jobId);
  const { data: workSetting = [] } = useLookup("workSetting");
  const { data: workType = [] } = useLookup("workType");
  const { data: workTerm = [] } = useLookup("workTerm");
  const { data: payRateTypes = [] } = useLookup("payRateType");
  const { data: solutionTypes = [] } = useLookup("solutionTypeNew");

  const isFieldLocked = isLockedJobNew(job?.jobState);

  const lockedJobStatusToolTipText = useMemo(() => {
    let jobState = job?.jobState;

    if (["closed_filled", "closed_cancelled"].includes(jobState)) {
      jobState = "closed";
    } else if (jobState === "on_hold") {
      jobState = "on hold";
    } else {
      jobState = "live";
    }
    return jobState;
  }, [job]);

  const SECTION_LOCKED_MESSAGE = `This section cannot be changed while the job status is ${lockedJobStatusToolTipText}.`;

  const form = useForm<z.infer<typeof JobsNewSchema>>({
    resolver: zodResolver(JobsNewSchema),
    defaultValues: {
      jobName: "",
      solutionType: "",
      startDate: "",
      openings: "",
      applicationFlow: "",
      workSettings: "",
      workType: "",
      workTerm: "",

      hoursPerWeekMin: "",
      hoursPerWeekMax: "",

      payRateType: "",
      salaryMin: "",
      salaryMax: "",
      hourlyMin: "",
      hourlyMax: "",
      isCommission: false,
      commission: "",
      unpaidInternship: "",

      whatsInItForYou: "",
      dayInLife: "",
      mustHaves: "",
    },
    reValidateMode: "onBlur",
  });

  const {
    handleSubmit,
    setValue,
    control,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = form;

  // Watch for the value of payRateType
  const payRateType = watch("payRateType");
  // Watch for the value of isCommission
  const isCommission = watch("isCommission");

  const onSubmit = async ({ ...values }: z.infer<typeof JobsNewSchema>) => {
    const {
      dayInLife,
      mustHaves,
      whatsInItForYou,
      hoursPerWeekMin,
      hoursPerWeekMax,
      payRateType,
      salaryMin,
      salaryMax,
      hourlyMin,
      hourlyMax,
      commission,
      unpaidInternship,
      ...restValues
    } = values;

    const jobDetailsNewCollectionData: JobDetailsNewCollection = {
      ...restValues,
      // jobName: title,
      companyId: job.companyId,
      creationDate: job.creationDate,
      jobState: job.jobState,

      // giving null placeholder values to the fields which have not been filled yet
      // applicationFlow: null,
      applyNowCounter: 0,
      clicksCounter: 0,
      closedFilledBy: "",
      closedReason: "",
      companyFullName: job.companyFullName,
      companyLogo: job.companyLogo,
      companyHQ: job.companyHQ,
      hoursPerWeek: {
        min: Number(hoursPerWeekMin),
        max: Number(hoursPerWeekMax),
      },
      instantTeamJobs: null,
      jobDescription: {
        dayInLife: dayInLife,
        mustHaves: mustHaves,
        whatsInItForYou: whatsInItForYou,
      },
      makeLiveDate: job.makeLiveDate,
      openings: Number(values.openings),
      payRate: {
        type: payRateType,
        ...(["salary", "hourly", "commission"].includes(payRateType) &&
        commission !== "" &&
        commission !== undefined
          ? {
              commission: commission,
            }
          : {
              commission: null,
            }),
        ...(payRateType === "unpaid_internship" &&
        unpaidInternship !== "" &&
        unpaidInternship !== undefined
          ? {
              unpaidInternship: unpaidInternship,
            }
          : {
              unpaidInternship: null,
            }),
        ...(payRateType === "salary" &&
        salaryMin !== "" &&
        salaryMax !== "" &&
        salaryMin !== undefined &&
        salaryMax !== undefined
          ? {
              salary: {
                min: Number(salaryMin),
                max: Number(salaryMax),
              },
            }
          : {
              salary: null,
            }),
        ...(payRateType === "hourly" &&
        hourlyMin !== "" &&
        hourlyMax !== "" &&
        hourlyMin !== undefined &&
        hourlyMax !== undefined
          ? {
              hourly: {
                min: Number(hourlyMin),
                max: Number(hourlyMax),
              },
            }
          : {
              hourly: null,
            }),
      },
      // solutionType: null,
      // startDate: null,
      // workSettings: null,
      // workTerm: null,
      // workType: null,
      // latestUpdate: new Date(),
    };

    await updateEntryForJobDetailsNewCollection(
      jobId,
      jobDetailsNewCollectionData,
    )
      .then((data) => {
        toast.success("Job (New) updated successfully");
      })
      .catch(() => {
        toast.error("There was an error updating Job (New)");
      });
  };

  const setIntialValuesToForm = (jobData: any) => {
    if (!jobData || (jobData && !Object.keys(jobData).length)) return;

    setValue("payRateType", jobData?.payRate?.type ?? "");

    setValue("jobName", jobData?.jobName ?? "");
    setValue("solutionType", job?.solutionType ?? "");
    setValue("startDate", job?.startDate?.toDate() ?? "");
    setValue("openings", jobData?.openings ?? "");
    setValue("applicationFlow", jobData?.applicationFlow ?? "");
    setValue("workSettings", jobData?.workSettings ?? "");
    setValue("workTerm", jobData?.workTerm ?? "");
    setValue("workType", jobData?.workType ?? "");

    setValue("hoursPerWeekMin", jobData?.hoursPerWeek?.min ?? "");
    setValue("hoursPerWeekMax", jobData?.hoursPerWeek?.max ?? "");

    setValue("whatsInItForYou", jobData?.jobDescription?.whatsInItForYou ?? "");
    setValue("dayInLife", jobData?.jobDescription?.dayInLife ?? "");
    setValue("mustHaves", jobData?.jobDescription?.mustHaves ?? "");

    setTimeout(() => {
      if (jobData?.payRate?.type === "salary") {
        setValue("salaryMin", String(jobData?.payRate?.salary?.min) ?? "");
        setValue("salaryMax", String(jobData?.payRate?.salary?.max) ?? "");
        setValue("commission", jobData?.payRate?.commission ?? null);
      } else if (jobData?.payRate?.type === "hourly") {
        setValue("hourlyMin", String(jobData?.payRate?.hourly?.min) ?? "");
        setValue("hourlyMax", String(jobData?.payRate?.hourly?.max) ?? "");
        setValue("commission", jobData?.payRate?.commission ?? null);
      } else if (jobData?.payRate?.type === "commission") {
        setValue("commission", jobData?.payRate?.commission ?? "");
      } else if (jobData?.payRate?.type === "unpaid_internship") {
        setValue("unpaidInternship", jobData?.payRate?.unpaidInternship ?? "");
      }

      setTimeout(() => {
        setValue(
          "isCommission",
          jobData?.payRate?.commission?.length ? true : false,
        );

        setTimeout(() => {
          if (jobData?.payRate?.type === "salary") {
            setValue("commission", jobData?.payRate?.commission ?? null);
          } else if (jobData?.payRate?.type === "hourly") {
            setValue("commission", jobData?.payRate?.commission ?? null);
          }
        }, 100);
      }, 500);
    }, 1000);
  };

  useEffect(() => {
    if (job && !isInitialized) {
      setIntialValuesToForm(job);
      setIsInitialized(true);
    }
  }, [job, isInitialized]);

  if (!job) return <></>;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-row justify-center">
          <div className="w-auto min-h-screen max-w-[850px]">
            <div className="grid gap-1 px-10 grid-cols-1 md:grid-cols-1 mb-4 mt-4">
              <GreenUnderlineHeading
                title="Job Information"
                showLockIcon={true}
                isLocked={isFieldLocked}
                text={SECTION_LOCKED_MESSAGE}
              />

              <Controller
                control={control}
                name="jobName"
                render={(field) => (
                  <Input
                    {...field}
                    display="Job Title"
                    placeholder="Enter Job Title"
                    readOnly={isFieldLocked}
                    error={errors?.jobName?.message}
                    required
                  />
                )}
              />

              {isFieldLocked ? (
                <Controller
                  control={control}
                  name="solutionType"
                  render={(field) => {
                    const { value, ...restField } = field;
                    const filteredValue = solutionTypes.find(
                      (st: any) => st.code === value,
                    );

                    const solutionTypeText = filteredValue?.label ?? "";

                    return (
                      <Input
                        {...restField}
                        value={solutionTypeText}
                        display="Solution Type"
                        placeholder="Solution Type"
                        readOnly={isFieldLocked}
                        error={errors?.solutionType?.message}
                        required
                      />
                    );
                  }}
                />
              ) : (
                <Controller
                  control={control}
                  name="solutionType"
                  render={(field) => (
                    <SelectGeneric
                      {...field}
                      options={solutionTypes}
                      display="Solution Type"
                      placeholder="Select a Solution Type"
                      disabled={isFieldLocked}
                      error={errors?.solutionType?.message}
                      required
                    />
                  )}
                />
              )}

              <Controller
                control={control}
                name="startDate"
                render={(field) => (
                  <DateGeneric
                    {...field}
                    display="Start Date"
                    timestamp={true}
                    readOnly={isFieldLocked}
                    error={errors?.startDate?.message}
                    required
                  />
                )}
              />

              <Controller
                control={control}
                name="openings"
                render={(field) => {
                  const { value, onChange, ...restField } = field;

                  return (
                    <Input
                      {...restField}
                      value={value === "" ? "" : Number(value)}
                      onChange={(e: any) => {
                        if (isNaN(Number(e.target.value))) {
                          return;
                        }

                        const valueAsNumber =
                          e.target.value === "" ? "" : Number(e.target.value);

                        onChange(valueAsNumber);
                      }}
                      display="Openings"
                      placeholder="Enter Openings"
                      readOnly={isFieldLocked}
                      error={errors?.openings?.message}
                      required
                    />
                  );
                }}
              />

              <Controller
                control={control}
                name="applicationFlow"
                render={(field) => (
                  <Input
                    {...field}
                    display="Application Flow"
                    placeholder="Enter Application Flow"
                    readOnly={isFieldLocked}
                    error={errors?.applicationFlow?.message}
                    required
                  />
                )}
              />

              <GreenUnderlineHeading
                title="Work Setting"
                showLockIcon={true}
                isLocked={isFieldLocked}
                text={SECTION_LOCKED_MESSAGE}
              />
              <Controller
                control={control}
                name="workSettings"
                render={(field) => (
                  <RadioGroup
                    {...field}
                    options={workSetting}
                    disabled={isFieldLocked}
                    error={errors?.workSettings?.message}
                    required
                  />
                )}
              />

              <GreenUnderlineHeading
                title="Work Type"
                showLockIcon={true}
                isLocked={isFieldLocked}
                text={SECTION_LOCKED_MESSAGE}
              />
              <Controller
                control={control}
                name="workType"
                render={(field) => (
                  <RadioGroup
                    {...field}
                    options={workType}
                    disabled={isFieldLocked}
                    error={errors?.workType?.message}
                    required
                  />
                )}
              />

              <GreenUnderlineHeading
                title="Work Term"
                showLockIcon={true}
                isLocked={isFieldLocked}
                text={SECTION_LOCKED_MESSAGE}
              />
              <Controller
                control={control}
                name="workTerm"
                render={(field) => (
                  <RadioGroup
                    {...field}
                    options={workTerm}
                    disabled={isFieldLocked}
                    error={errors?.workTerm?.message}
                    required
                  />
                )}
              />

              <GreenUnderlineHeading
                title="Work Per Week (Hrs)"
                showLockIcon={true}
                isLocked={isFieldLocked}
                text={SECTION_LOCKED_MESSAGE}
              />
              <div className="flex flex-row gap-[20px]">
                <Controller
                  control={control}
                  name="hoursPerWeekMin"
                  render={(field) => {
                    const { value, onChange, ...restField } = field;

                    return (
                      <Input
                        {...restField}
                        value={value === "" ? "" : value }
                        onChange={(e: any) => {
                          const inputValue = e.target.value;
                          const isValidInput = /^\d*\.?\d{0,1}$/.test(inputValue);
                          if (!isValidInput) return;
                          onChange(inputValue);
                        }}
                        display="Min (Hrs)"
                        placeholder="Min (Hrs)"
                        readOnly={isFieldLocked}
                        error={errors?.hoursPerWeekMin?.message}
                        required
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="hoursPerWeekMax"
                  render={(field) => {
                    const { value, onChange, ...restField } = field;

                    return (
                      <Input
                        {...restField}
                        value={value === "" ? "" : value}
                        onChange={(e: any) => {
                          const inputValue = e.target.value;
                          const isValidInput = /^\d*\.?\d{0,1}$/.test(inputValue);
                          if (!isValidInput) return;
                          onChange(inputValue);
                        }}
                        display="Max (Hrs)"
                        placeholder="Max (Hrs)"
                        readOnly={isFieldLocked}
                        error={errors?.hoursPerWeekMax?.message}
                        required
                      />
                    );
                  }}
                />
              </div>

              <GreenUnderlineHeading
                title="Pay Rate"
                showLockIcon={true}
                isLocked={isFieldLocked}
                text={SECTION_LOCKED_MESSAGE}
              />
              <Controller
                control={control}
                name="payRateType"
                render={(field) => (
                  <RadioGroup
                    {...field}
                    options={payRateTypes}
                    disabled={isFieldLocked}
                    error={errors?.payRateType?.message}
                    required
                  />
                )}
              />

              {payRateType === "salary" && (
                <div className="flex flex-col">
                  <div className="flex flex-row gap-[20px] mt-2">
                    <Controller
                      control={control}
                      name="salaryMin"
                      render={(field) => {
                        return (
                          <InputCurrency
                            {...field}
                            display="Min"
                            placeholder="Min"
                            readOnly={isFieldLocked}
                            error={errors?.salaryMin?.message}
                            required
                          />
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name="salaryMax"
                      render={(field) => {
                        const { value, onChange, ...restField } = field;

                        return (
                          <InputCurrency
                            {...field}
                            display="Max"
                            placeholder="Max"
                            readOnly={isFieldLocked}
                            error={errors?.salaryMax?.message}
                            required
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-[20px] mt-2">
                    <Controller
                      control={control}
                      name="isCommission"
                      render={(field) => (
                        <Checkbox
                          {...field}
                          id="isCommissionForSalary"
                          label="Commission"
                          disabled={isFieldLocked}
                          error={errors?.workSettings?.message}
                          required
                        />
                      )}
                    />
                    {isCommission && (
                      <div className="mb-2">
                        <Controller
                          control={control}
                          name="commission"
                          render={(field) => (
                            <TextArea
                              {...field}
                              display="Commission"
                              placeholder="Enter Commission"
                              readOnly={isFieldLocked}
                              error={errors?.commission?.message}
                              required
                            />
                          )}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}

              {payRateType === "hourly" && (
                <div className="flex flex-col">
                  <div className="flex flex-row gap-[20px] mt-2">
                    <Controller
                      control={control}
                      name="hourlyMin"
                      render={(field) => {
                        return (
                          <InputCurrency
                            {...field}
                            display="Min"
                            placeholder="Min"
                            readOnly={isFieldLocked}
                            error={errors?.hourlyMin?.message}
                            required
                          />
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name="hourlyMax"
                      render={(field) => {
                        return (
                          <InputCurrency
                            {...field}
                            display="Max"
                            placeholder="Max"
                            readOnly={isFieldLocked}
                            error={errors?.hourlyMax?.message}
                            required
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-[20px] mt-2">
                    <Controller
                      control={control}
                      name="isCommission"
                      render={(field) => (
                        <Checkbox
                          {...field}
                          id="isCommissionForHourly"
                          label="Commission"
                          disabled={isFieldLocked}
                          error={errors?.workSettings?.message}
                          required
                        />
                      )}
                    />
                    {isCommission && (
                      <div className="mb-2">
                        <Controller
                          control={control}
                          name="commission"
                          render={(field) => (
                            <TextArea
                              {...field}
                              display="Commission"
                              placeholder="Enter Commission"
                              readOnly={isFieldLocked}
                              error={errors?.commission?.message}
                              required
                            />
                          )}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}

              {payRateType === "commission" && (
                <div className="mt-2">
                  <Controller
                    control={control}
                    name="commission"
                    render={(field) => (
                      <TextArea
                        {...field}
                        display="Commission"
                        placeholder="Enter Commission"
                        readOnly={isFieldLocked}
                        error={errors?.commission?.message}
                        required
                      />
                    )}
                  />
                </div>
              )}

              {payRateType === "unpaid_internship" && (
                <div className="mt-2">
                  <Controller
                    control={control}
                    name="unpaidInternship"
                    render={(field) => (
                      <TextArea
                        {...field}
                        display="Free Internship"
                        placeholder="Enter Free Internship"
                        readOnly={isFieldLocked}
                        error={errors?.unpaidInternship?.message}
                        required
                      />
                    )}
                  />
                </div>
              )}

              <GreenUnderlineHeading
                title="Job Description"
                showLockIcon={true}
                isLocked={isFieldLocked}
                text={SECTION_LOCKED_MESSAGE}
              />

              <div className="mb-2">
                <Controller
                  control={control}
                  name="whatsInItForYou"
                  render={(field) => (
                    <Wysiwyg
                      {...field}
                      rows={10}
                      display="What's in it for you?"
                      readOnly={isFieldLocked}
                      placeholder={"What's in it for you?"}
                      directions=""
                      editorStyle={{ height: "240px" }}
                      error={errors?.whatsInItForYou?.message}
                      required
                    />
                  )}
                />
              </div>

              <div className="mb-2">
                <Controller
                  control={control}
                  name="dayInLife"
                  render={(field) => (
                    <Wysiwyg
                      {...field}
                      rows={10}
                      display="Day in the Life"
                      readOnly={isFieldLocked}
                      placeholder={"Day in the Life"}
                      directions=""
                      editorStyle={{ height: "240px" }}
                      error={errors?.dayInLife?.message}
                      required
                    />
                  )}
                />
              </div>

              <div className="mb-2">
                <Controller
                  control={control}
                  name="mustHaves"
                  render={(field) => (
                    <Wysiwyg
                      {...field}
                      rows={10}
                      display="Must Haves/What You Bring to the Table"
                      readOnly={isFieldLocked}
                      placeholder={"Must Haves/What You Bring to the Table"}
                      directions=""
                      editorStyle={{ height: "240px" }}
                      error={errors?.mustHaves?.message}
                      required
                    />
                  )}
                />
              </div>

              {!isFieldLocked && (
                <div className="mt-3 flex flex-row justify-center gap-[36px]">
                  <button
                    type="button"
                    disabled={isSubmitting}
                    className="h-[60px] w-[210px] rounded-[70px] border-[1px] border-[#15415E] py-[20px] px-[70px] hover:border-[#1d5174] text-[#15415E] text-[16px] leading-[19.6px] font-[400]"
                    onClick={() => reset()}
                  >
                    Clear All
                  </button>

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="h-[60px] w-[210px] rounded-[70px] bg-[#15415E] py-[20px] px-[70px] hover:bg-[#1d5174] text-[#FFFFFF] text-[16px] leading-[19.6px] font-[400]"
                  >
                    {isSubmitting ? "Saving..." : "Save"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
      <ToastAlert />
    </>
  );
}

export default JobNewInformation;
