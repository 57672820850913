import { forwardRef } from "react";

interface CheckboxProps {
  id: string;
  value: boolean;
  name?: string;
  label?: string;
  onChange?: (value: string) => void;
  required?: boolean;
  error?: string;
  touched?: boolean;
}

const Checkbox = forwardRef(
  (
    {
      id,
      label,
      value,
      name,
      onChange,
      required,
      error,
      touched,
      ...props
    }: CheckboxProps,
    ref: any,
  ) => {
    const handleChange = (e: any) => {
      const { checked } = e.target;
      if (onChange) {
        onChange(checked); // Invoke the callback with the selected value
      }
    };

    return (
      <div id={name} className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
        <div className="flex items-start">
          <input
            className="h-5 w-5 shadow text-instant-teams-blue-L1 leading-8 bg-white rounded border-instant-teams-blue-Main outline-none focus:ring-1"
            id={id}
            type="checkbox"
            ref={ref}
            onChange={handleChange}
            checked={value}
            {...props}
          />
          <label
            className="mb-1 px-2 text-sm font-semibold text-instant-teams-blue-Main"
            htmlFor={id}
          >
            {label}
          </label>
        </div>
      </div>
    );
  },
);

Checkbox.displayName = "Checkbox";

export default Checkbox;
