import { useState } from "react";
import { useAuth } from "../../../../../providers/auth";
import { useNavigate } from "react-router-dom";
import InfoIcon from "../../../../dashboard/AdminMetricDashboard/InfoTextWithIcon";

function ForgetPasswordComponent_NewUI() {
    const navigate = useNavigate()
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const auth = useAuth();

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleToggleConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleSavePassword = () => {
        console.log("Password reset startet");
        auth
      .confirmPasswordReset(password)
      .then(() => {
        console.log("Password reset successfully");
        setPassword("");
          setConfirmPassword("");
          console.log("password reset successfully ")
          
          navigate("/auth/signin")
      })
      .catch((error:any) => {
        console.log("Error:", error);
        console.log("Password reset successfully error");
      })
      .finally(() => {
        console.log("Password reset successfully finanly");
      });
    }

    return (
        <div className="flex min-h-screen">
            {/* Left Section with Background Image */}
            <div
                className="w-1/2 bg-cover bg-center h-screen relative"
                style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + '/images/banner_bg.png'})`,
                }}
            >
                <div className="absolute top-8 left-8">
                    <img
                        src= "/images/InstantTeamsLogo_Main_1.png"
                        alt="Instant Teams Logo"
                        className="w-48"
                    />
                </div>
            </div>

            {/* Right Section with Form */}
            <div className="w-1/2 flex flex-col rounder-l-full justify-center px-16 py-8 bg-white">

                {/* <div className="flex justify-between w-full mb-36 items-center">
                    <p>
                        Back
                    </p>
                    <p>close</p>
                </div> */}
                <div className=" justify-center items-center">
                    <h1 className="text-2xl text-center text-instant-teams-blue-Main text-lg font-semibold mb-2">Create New Password</h1>
                    <p className="text-gray-300 text-center  mb-6">Please enter a new password</p>

                </div>

                {/* Password Input */}
                <div className="mb-4 w-full flex justify-center  relative">
                    <div className="w-[70%]">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                            Password <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-1 relative">
                            <input
                                type={showPassword ? "text" : "password"}
                                id="password"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <button
                                type="button"
                                className="absolute inset-y-0 right-3 flex items-center"
                                onClick={handleTogglePassword}
                            >
                                <i className={`fas fa-eye${showPassword ? "" : "-slash"}`}></i>
                            </button>
                            <button type="button" className="absolute inset-y-0 right-10 flex items-center">
                            <InfoIcon viewDown={true} singupFlow={true} tooltipText={`
             <ul>
                <li>At least 8 characters</li>
                <li>At least one capital letter</li>
                <li>At least one lowercase letter</li>
                <li>At least one number or symbol</li>
              </ul>`}/>
                            </button>
                        </div>
       
                        <div className="mb-6 relative">
                            <label htmlFor="confirm-password" className="block mt-4 text-sm font-medium text-gray-700">
                                Confirm Password <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1 relative">
                                <input
                                    type={showConfirmPassword ? "text" : "password"}
                                    id="confirm-password"
                                    className="w-full px-3 py-2  border-gray-800 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                <button
                                    type="button"
                                    className="absolute inset-y-0 right-3 flex items-center"
                                    onClick={handleToggleConfirmPassword}
                                >
                                    <i className={`fas fa-eye${showConfirmPassword ? "" : "-slash"}`}></i>
                                </button>
                                <button type="button" className="absolute inset-y-0 right-10 flex items-center">
                                <InfoIcon viewDown={true} singupFlow={true} tooltipText={`
             <ul>
                <li>At least 8 characters</li>
                <li>At least one capital letter</li>
                <li>At least one lowercase letter</li>
                <li>At least one number or symbol</li>
              </ul>`}/>
                                </button>
                               
                            </div>

                            {/* Save Password Button */}
                            <div className="mt-14 flex justify-center">
                                    <button onClick={handleSavePassword} className="w-auto rounded-full bg-instant-teams-blue-Main text-white py-2 px-10  hover:bg-blue-800 transition-colors">
                                        Save Password
                                    </button>
                                </div>
                        </div>
                    </div>

                </div>




            </div>
        </div>
    );
}

export default ForgetPasswordComponent_NewUI;
